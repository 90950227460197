import Page from "../components/Page";
import "./Catering.css";

function Catering() {
  return (
    <Page>
      <div className="contact-us">
        <h1>Bring Our Flavor to Your Next Event!</h1>
        <br />

        <p>Due to the high volume of orders in our kitchen, </p>
        <p>
          we are currently unable to accept small orders via social media
          (Instagram, Facebook) or email.{" "}
        </p>
        <p>Orders must be placed in-store only. </p>
        <p>let us handle the food so you can focus on making memories. </p>
        <br />
        <p>
          For catering needs, please contact us at least two weeks in advance.{" "}
        </p>

        <p>Please note that we do not offer delivery for catering orders; </p>
        <p>customers will need to pick up their orders in-store. </p>
        <p>
          Our catering options are similar to the dishes available in-store and
          are priced accordingly.
        </p>
        <br />

        <p>Thank you for your understanding!</p>
        <img src="/event.png" alt="event" />
      </div>
    </Page>
  );
}

export default Catering;
