import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faInstagram } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const footerLinksLeft = [
    { text: "Contact us", href: "/contactus" },
    { text: "Pre-orders & Catering", href: "/catering" },
    { text: "Opening Hours", href: "/openinghours" },
  ];

  return (
    <footer className="footer">
      <div className="footer-column">
        <img src="logo.svg" alt="Rice Kitchen Come logo" className="logo" />
        <address className="address">Flæsketorvet 44, 1711 København</address>
        <div className="social-icons">
          <a href="https://www.tiktok.com/@come.rice.kitchen">
            <FontAwesomeIcon
              icon={faTiktok}
              size="2x"
              style={{ marginRight: "20px" }}
            />
          </a>
          <a href="https://www.instagram.com/come.rice.kitchen/">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>
      </div>
      <div className="footer-column">
        <ul className="footer-links">
          {footerLinksLeft.map((link, index) => (
            <li key={index}>
              <Link to={link.href} onClick={handleLinkClick}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="footer-column">
        <ul className="footer-links">
          <li>
            <a href="/supermarket">UME Asian Supermarket</a>
          </li>
          <li>
            <a href="https://www.findsmiley.dk/1451887">
              <img
                src="/findsmile.png"
                alt="findsimle"
                className="findsimile"
              />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
