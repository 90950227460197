import React from "react";
import "./SideDish.css";
import Page from "../components/Page";
import StyledHeader from "../components/StyledHeader";

const SideDish = () => {
  const menu = {
    salads: [
      { name: "Yuzu Daikon(Radish) Salad", description: "" },
      { name: "Goma Wakama Salad", description: "" },
      { name: "Kimchi Salad", description: "" },
      { name: "Age Hitashi(Eggplant) Salad", description: "" },
      { name: "Tofu Salad", description: "" },
      { name: "Pumpkin Salad", description: "" },
    ],
    sashimi: [
      { name: "Salmon", description: "" },
      { name: "Bluefin Tuna: Akame", description: "" },
      { name: "Chūtoro", description: "" },
      { name: "Toro", description: "" },
      { name: "Japanese Hamachi", description: "" },
      { name: "Scallop", description: "" },
      { name: "Squid", description: "" },
    ],
    friedSnacks: [
      { name: "Chicken Karaage", description: "" },
      { name: "Menchi Katsu", description: "" },
      { name: "Rice Croquette", description: "" },
      { name: "Tempura Seaweed Shrimp", description: "" },
    ],
  };

  const regularItems = [
    { name: "Salmon Mayo", note: "(Gluten free)" },
    { name: "Spicy Tuna Mayo" },
    { name: "Tempura Black Tiger Shrimp" },
    { name: "Teriyaki Chicken" },
    { name: "Beef & Mustard Leaf" },
    { name: "Shiitake", note: "(Vegan, gluten free)" },
    { name: "Natto", note: "(Vegan, gluten free)" },
    { name: "Green Chilli & Miso", note: "(Vegan, gluten free)" },
  ];

  const seasonalItems = [
    { name: "Butter Curry Chicken" },
    { name: "Umeboshi" },
    { name: "Spicy Lamb" },
    { name: "Walnut Miso", note: "(Vegan, gluten free)" },
  ];

  return (
    <Page>
      <div className="menu-layout">
        <h1>Onigiri</h1>

        <div className="menu-container">
          <ul className="menu-list">
            {regularItems.map((item, index) => (
              <li key={index}>
                {item.name}{" "}
                {item.note && <span className="note">{item.note}</span>}
              </li>
            ))}
          </ul>
          <ul className="menu-list">
            <li>
              <strong>Seasonal&limited:</strong>
            </li>
            {seasonalItems.map((item, index) => (
              <li key={index}>
                {item.name}{" "}
                {item.note && <span className="note">{item.note}</span>}
              </li>
            ))}
          </ul>
        </div>
        <StyledHeader firstWord="Try">
          our delicious side dish. We offer fresh sashimi, fresh made salads and
          fried snacks everyday in store :)
        </StyledHeader>

        <div className="image-container">
          <img src="/sidedish.png" alt="Sashimi and salads" />
        </div>
        <div className="menu-container">
          <div className="menu-column">
            <h2>Salad</h2>
            <img src="/leaf.svg" alt="leaf icon" />
            <ul>
              {menu.salads.map((item, index) => (
                <li key={index}>
                  {item.name} {item.description}
                </li>
              ))}
              <img src="/fish.svg" alt="fish icon" />
              <li>Salmon Salad</li>
            </ul>
          </div>
          <div className="menu-column">
            <h2>Sashimi</h2>
            <img src="/fish.svg" alt="fish icon" />
            <ul>
              {menu.sashimi.map((item, index) => (
                <li key={index}>
                  {item.name} {item.description}
                </li>
              ))}
            </ul>
          </div>
          <div className="menu-column">
            <h2>Tempura</h2>
            <img src="/meat.svg" alt="shrimp icon" />

            <ul>
              {menu.friedSnacks.map((item, index) => (
                <li key={index}>
                  {item.name} {item.description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SideDish;
