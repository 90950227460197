import { NavLink } from "react-router-dom";
import "./Navigation.css";

function Navigation() {
  return (
    <nav className="navigation">
      <NavLink to="/side-dish" activeClassName="active">
        Onigiri & Side dish
      </NavLink>
      <NavLink to="/onigiri" activeClassName="active">
        What is Onigiri
      </NavLink>
      <NavLink to="/sake" activeClassName="active">
        Sake
      </NavLink>
      <NavLink to="/catering" activeClassName="active">
        Pre-orders & Catering
      </NavLink>
    </nav>
  );
}

export default Navigation;
